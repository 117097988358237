import React from "react";
import axios from 'axios';
import NewsletterModal from "./NewsletterModal";

export default function Newsletter({ initialDelay = 3000 }) {
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const didSubscribe = window.localStorage.getItem("did-subscriber");
    !didSubscribe &&
      setTimeout(() => {
        setOpen(true);
      }, initialDelay);
  }, [setOpen, initialDelay]);

  return (
    <React.Fragment>
      {open && (
        <NewsletterModal
          onEmail={async (email) => {
            /*
             
            - Make use of this email here.
            
            - Example:

            await fetch("/subscribe", {
              method: "POST",
              body: JSON.stringify({ email }),
            });
            
            */
           const url = 'https://tracker.moxigps.com/form/submit?formId=1';
           //const url = 'https://tracker.moxigps.com/repost.php';
           //const url = 'https://tracker.moxigps.com/mautic-form-submit.php';
           var bodyFormData = new FormData();
           bodyFormData.append('mauticform[email]', email);
           bodyFormData.append('mauticform[formId]', "1");

           await axios.request ({
            url: `${url}`,
            method: 'POST',
            data: bodyFormData,
            headers: {
              // 'X-Requested-With': 'XMLHttpRequest',
              //'X-Requested-With' : 'XMLHttpRequest',
              //'Content-Type': 'application/json'
              'Content-Type': 'multipart/form-data'
          },

        }).then(data => {
       
             console.log('ok', data);
       
           })
           .catch(error => {
       
             console.log('Error', error);
       
           });

            window.localStorage.setItem("did-subscriber", "yes");
            setTimeout(() => {
              setOpen(false);
            }, 2000);
          }}
          onCancel={() => setOpen(false)}
        />
      )}
    </React.Fragment>
  );
}
