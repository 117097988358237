import React from "react";
import { FiX, FiMail } from "react-icons/fi";

import validateEmail from "@helpers/validateEmail";

import "./style.css";

export default function NewsletterModal({ onEmail, onCancel }) {
  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [done, setDone] = React.useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      setError(false);
      setLoading(true);
      await onEmail(email);
      setDone(true);
      setLoading(false);
    } else {
      setError(true);
    }
  };

  return (
    <div className="nl-modal__wrapper">
      <div className="nl-modal">
        <button onClick={onCancel} className="nl-modal__close-button">
          <FiX size="20" />
        </button>
        <FiMail size="64" strokeWidth="1.5" className="nl-modal__mail-icon" />
        <h2 className="nl-modal__top">
          {done
            ? "Thank you for subscribing!"
            : "Want my BEST cashflow investment tips? 👉💰"}
        </h2>
        <div className="nl-modal__bottom">
          <p>6,000+ are onboard already (No salesy emails)
            Proven strategies to start and grow your cashflow.</p>
          <form onSubmit={handleSubmit} className="nl-modal__form">
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className={error ? "error" : ""}
              placeholder="example@mail.com"
            />
            <button disabled={loading}>Yes! I wan't more CashFlow</button>
          </form>
        </div>
      </div>
    </div>
  );
}
